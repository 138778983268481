<template>
<section class="pt-20 flex flex-col mx-auto text-center h-screen gap-10">
    <h1 class="uppercase text-2xl lg:text-4xl xl:text-5xl font-semibold pt-8 leading-normal
                 md:leading-relaxed text-background-primary">
        {{$t('form.submitted.text1')}}<br/>{{$t('form.submitted.text2')}}</h1>
    <router-link to="/" class="">
        <button class="px-2 py-1 rounded-lg border-2 border-background-light cursor-pointer
             hover:text-background-light text-white bg-background-light
             hover:bg-background-accent duration-100">
            {{$t('form.submitted.button')}}
        </button>
    </router-link>
</section>
</template>
<script>
export default {
  name: 'FormSubmitted',
};
</script>
